<template>
  <div class="home">
    <van-pull-refresh
      :head-height="50"
      v-model="refreshing"
      @refresh="onRefresh"
    >
      <van-row>
        <van-col span="3" class="home-header" @click="onDataClick"
          ><svg-icon :data_iconName="'data'" :className="'data-icon'"
        /></van-col>
        <van-col span="15" class="home-header van-ellipsis">{{
          company.name
        }}</van-col>
        <van-col span="6" class="home-header text-right" @click="sign_out">
          <svg-icon :data_iconName="'signout'" :className="'signout_icon'" />
        </van-col>
      </van-row>
      <div class="home-body">
        <van-row class="home-body-header" gutter="20">
          <van-col span="4">
            <div class="avatar">
              <van-image
                round
                style="width: 1rem; height: 1rem"
                :src="user.avatar"
              >
              </van-image></div
          ></van-col>
          <van-col span="16" class="van-ellipsis">
            <div class="username">
              {{ user.username
              }}<span class="status" @click="checkCompanyStatus">{{
                company.status_text
              }}</span>
            </div>
            <div class="mobile van-ellipsis">
              法人:{{
                company.legal_person ? company.legal_person : "未完善"
              }}
              &nbsp;&nbsp;电话:{{
                company.telephone ? company.telephone : user.mobile
              }}
            </div>
          </van-col>
          <van-col span="3" @click="onSettingClick">
            <div class="setting-icon-container">
              <svg-icon
                :data_iconName="'edit-deep'"
                :className="'setting_icon'"
              />
            </div>
          </van-col>
        </van-row>

        <div class="home-body-footer">
          <van-row gutter="20">
            <van-col
              span="12"
              v-for="(item, index) in menus"
              :key="index"
              class="home-body-footer-item"
              @click="onMenuClick(item)"
            >
              <!-- <svg-icon :data_iconName="item.icon" :className="'item_icon'" />
              <div class="item-text">{{ item.text }}</div> -->
              <van-badge
                dot
                v-if="index == 6 && item.notification_unread_count > 0"
              >
                <svg-icon :data_iconName="item.icon" :className="'item_icon'" />
                <div class="item-text">{{ item.text }}</div>
              </van-badge>
              <van-badge
                dot
                v-else-if="index == 7 && item.emergency_unapproved_count > 0"
              >
                <svg-icon :data_iconName="item.icon" :className="'item_icon'" />
                <div class="item-text">{{ item.text }}</div>
              </van-badge>
              <van-badge
                dot
                v-else-if="index == 9 && item.training_unread_count > 0"
              >
                <svg-icon :data_iconName="item.icon" :className="'item_icon'" />
                <div class="item-text">{{ item.text }}</div>
              </van-badge>
              <div v-else>
                <svg-icon :data_iconName="item.icon" :className="'item_icon'" />
                <div class="item-text">{{ item.text }}</div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import { Dialog } from "vant";
import cookies from "../../utils/cookies";
import { Toast } from "vant";
export default {
  name: "home",
  data() {
    return {
      status_text: ["营业中"],
      menus: [
        {
          text: "扫一扫",
          icon: "scan",
          path: "/scan",
        },
        {
          text: "个人中心",
          icon: "profile-default",
          path: "/profile",
        },
        {
          text: "导游管理",
          icon: "guide-default",
          path: "/guider",
        },
        {
          text: "司机管理",
          icon: "profile-default",
          path: "/drivers",
        },
        {
          text: "行程管理",
          icon: "trip-default",
          path: "/trip",
        },
        {
          text: "出团管理",
          icon: "tuan-default",
          path: "/tuan",
        },
        {
          text: "消息通知",
          icon: "message-default",
          path: "/message",
          notification_unread_count: 0,
        },
        {
          text: "反馈管理",
          icon: "train-default",
          path: "/feedbacks",
          emergency_unapproved_count: 0,
        },
        {
          text: "星级申报",
          icon: "tuan-default",
          path: "/startManage",
        },
        {
          text: "培训管理",
          icon: "tuan-default",
          path: "/trainManage",
          training_unread_count: 0,
        },
      ],
      company: {},
      user: {},
      refreshing: false,
      loading: true,
    };
  },
  methods: {
    onMenuClick(item) {
      if (item.text == "培训资料") {
        Toast("暂无开放");
        return false;
      }
      if (item.text != "个人中心" && this.company.status == "0") {
        Toast("您的账号还未审核，暂无操作权限！");
        return false;
      }
      if (item.text != "个人中心" && this.company.status == "2") {
        Toast("您的账号审核被拒绝，暂无操作权限！");
        return false;
      }
      this.$router.push(item.path);
    },
    onSettingClick() {
      this.$router.push("/profile/edit");
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.get_current_company();
      this.count_info();
    },
    async get_current_company() {
      const _self = this;
      const res = await this.$apis.get_current_company();
      this.user = res.data.user;
      this.company = res.data.company;
      cookies.set("companyStatus", res.data.company.status);
      this.loading = false;
      this.refreshing = false;

      if (res.data.company.status == "2") {
        this.show_denied_message();
      }
    },
    show_denied_message() {
      const _self = this;
      this.$dialog
        .confirm({
          title: "注册申请被拒绝",
          message: "原因:" + _self.company.reject_reason,
          confirmButtonText: "修改提交",
          cancelButtonText: "取消",
        })
        .then(() => {
          _self.$router.push("/profile/edit");
        })
        .catch(() => {});
    },
    checkCompanyStatus() {
      if (this.company.status == 2) {
        this.show_denied_message();
      }
    },
    async count_info() {
      const res = await this.$apis.count_info();
      this.menus[7].emergency_unapproved_count =
        res.data.emergency_unapproved_count;
      this.menus[6].notification_unread_count =
        res.data.notification_unread_count;
      this.menus[9].training_unread_count = res.data.training_unread_count;
      // this.companies_count = res.data.companies_count;
      // this.guides_count = res.data.guides_count;
      // this.plans_count = res.data.plans_count;
      // this.loading = false;
      // this.refreshing = false;
    },
    sign_out() {
      const _self = this;
      Dialog.confirm({
        // title: "标题",
        message: "确定退出吗?",
      })
        .then(() => {
          // on confirm
          _self.$router.push("/login");
        })
        .catch(() => {
          // on cancel
        });
    },
    onDataClick() {
      if (this.company.status == "0") {
        Toast("您的账号还未审核，暂无操作权限！");
        return false;
      }
      this.$router.push("/dataCenter");
    },
  },
  created() {
    this.onRefresh();
    // this.get_current_company()
    if (!this.user) {
      this.$router.push("/login");
    }
  },
};
</script>
<style scoped>
.home {
  padding: 0.3rem;
  height: 100vh;
  /* overflow: hidden;  */
  overflow-y: scroll;
}
.home-header {
  color: #fff;
  font-weight: bold;
  font-size: 0.5rem;
  height: 10vh;
  text-shadow: 0 0 2px #000;
}
.home-body {
  width: 100%;
  padding-bottom: 1rem;
}
.home-body-header {
  background: #fff;
  box-shadow: 0 0 14px 0 rgba(29, 62, 203, 0.64);
  border-radius: 28px;
  border-radius: 28px;
  padding: 0.5rem 0.3rem;
}
.home-body-header .avatar {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
}
/* .home-body-header .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
} */
.home-body-header .username {
  height: 0.5rem;
  line-height: 0.5rem;
  color: #333;
  font-size: 0.3rem;
  font-weight: bold;
}
.home-body-header .mobile {
  height: 0.5rem;
  line-height: 0.5rem;
  color: #666;
  font-size: 0.25rem;
}
.home-body-middle {
  background: #fff;
  height: 15vh;
  box-shadow: 0 0 14px 0 rgba(29, 62, 203, 0.64);
  border-radius: 28px;
  border-radius: 28px;
  padding: 0.5rem;
}
.home-body-middle .number {
  color: #333;
  font-size: 24px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
  height: 50%;
}
.home-body-middle .text {
  color: #666;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  height: 50%;
}
.home-body-footer {
  background: #fff;
  margin-top: 2vh;
  margin-bottom: 3rem;
  box-shadow: 0 0 14px 0 rgba(29, 62, 203, 0.64);
  border-radius: 28px;
  border-radius: 28px;
}
.home-body-footer-item {
  height: 18.3vh;
  text-align: center;
  padding: 0.3rem;
}
.item-text {
  font-size: 16px;
  color: #666666;
}
.item_icon {
  font-size: 1.4rem;
}
.sitting {
  position: absolute;
  top: -0.2rem;
  right: 0px;
}
.setting-icon-container {
  height: 1rem;
  line-height: 1rem;
  text-align: right;
}
.setting_icon {
  font-size: 0.8rem;
  color: #666;
}
.signout {
  font-size: 0.8rem;
  position: absolute;
  top: 0.2rem;
  right: 0.4rem;
  color: #fff;
}
.status {
  font-size: 12px;
  display: inline-block;
  color: #40a9ff;
  background: rgba(64, 169, 255, 0.16);
  height: 0.5rem;
  padding: 0 0.2rem;
  margin-left: 0.2rem;
  border-radius: 0.25rem;
  line-height: 0.55rem;
}
.text-right {
  text-align: right;
}
.signout_icon {
  font-size: 0.8rem;
}
.data-icon {
  font-size: 0.8rem;
}
</style>